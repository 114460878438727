import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogSeven from "../../components/Blog/BlogsList/BlogSeven"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogSeven />
    </BlogLayout>
  )
}

export default BlogContent
